/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import { Grid, MenuItem, TextField, Box, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const DeployResources = ({
  selectedUrl,
  selectedRam,
  selectedCpu,
  urlList,
  ramList,
  cpuList,
  onSelectUrl,
  onSelectRam,
  onSelectCpu,
  environment,
  application
}) => {
  const applications = useSelector(state => state.applications, shallowEqual);
  console.log(application);
  console.log(environment);
  console.log(urlList)
  console.log(applications)
  console.log(applications.list)

  const [targetUrls, setTargetUrls] = useState([]);

 useEffect(() => {
    try{      		
      const targetEnvironmentType = environment.type;
      const targetEnvironmentObject = applications?.list?.find(env => Object.keys(env)[0] === targetEnvironmentType)
      const targetEnvironment = targetEnvironmentObject[targetEnvironmentType];
      console.log(targetEnvironment)

      const targetApplicationSourceId = application.sourceAppId;

      const appInstanceInTargetEnv = targetEnvironment?.data?.find(app => app?.sourceAppId === targetApplicationSourceId);
      console.log(appInstanceInTargetEnv)

      if(!appInstanceInTargetEnv) return setTargetUrls(urlList);

      const targetEnvironmentUrls = targetEnvironment?.urls;
      console.log(targetEnvironmentUrls)

      const applicationUrlInEnv = targetEnvironmentUrls.find(url => url._id === appInstanceInTargetEnv?.url);
      console.log(applicationUrlInEnv);

      setTargetUrls([{...applicationUrlInEnv, version: appInstanceInTargetEnv?.version}]);
      onSelectUrl(applicationUrlInEnv._id)
    }catch(error){
      console.log(error);
      setTargetUrls(urlList);
    }
 }, [environment, application, urlList, applications]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectUrl(e.target.value)}
          label="Select url"
          value={selectedUrl || targetUrls[0] || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {console.log(targetUrls)}
          {(targetUrls && targetUrls.length > 0) && targetUrls.map(
            url => (
                <MenuItem key={url._id} value={url._id}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%"
                    }}
                  >
                    <span>{url.value}</span>
                    {url?.version 
                      ?  
                        <Typography variant="body2" color="secondary">
                          {url.version}
                        </Typography> 
                      : null
                    }
                  </Box>
                </MenuItem>
              )
          )}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectCpu(e.target.value)}
          label="Select CPU"
          value={selectedCpu || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {cpuList.map(cpu => (
            <MenuItem key={cpu} value={cpu}>
              {`${cpu} m`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectRam(e.target.value)}
          label="Select RAM"
          value={selectedRam || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {ramList.map(ram => (
            <MenuItem key={ram} value={ram}>
              {`${ram} mi`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default React.memo(DeployResources);
