/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Grid,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Divider,
  CardMedia,
  Typography,
  IconButton,
  Avatar,
  Collapse
} from "@material-ui/core";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfTooltip from "../vf/VfTooltip";
import AppActionsMenu from "./AppActionMenu/AppActionMenu";
import { DEPLOYMENT_FLOWS } from "../../utils/constants";
import AWS_S3 from "assets/images/aws-s3.svg";
import Kubernetes from "assets/images/kubernetes.svg";
import i18n from '../../i18n';
import PagesList from "./PagesList";

const useStyles = makeStyles({
  root: {
    "& .MuiTabs-root": {
      "& .MuiIconButton-root": {
        margin: "11px 12px",
      },
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#e8e8e8',
    borderRadius: '0px',
    padding: '5px 15px',
    cursor: 'pointer'
  },
  CardContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    padding: '10px !important',
    borderRadius: '5px',
    border: '1px solid #e7e7e7',
    alignItems: 'center',
    "&:hover": {
      color: "#E60000",
      backgroundColor: 'white'
    }
  },
  AppInfo:{
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%"
  },
  AppInfoBasic:{
    display: 'flex',
    flexDirection: "row",
    flex: 1,
    justifyContent: 'space-between',
    padding: '10px !important',
    borderRadius: '5px',
    border: '1px solid #e7e7e7',
    alignItems: 'center',
  },
  CardContentItem: {
    margin: 0
  },
  buttonContainer: {
    display:"flex",
    marginBottom:"10px"
  },
  infoButton: {
    zIndex:"100",
    display:"flex",
    height: "45px",
    background: "#DBDBDB",
    borderRadius: "5px",
    padding:"10px",
    margin:"10px",
    justifyContent: "space-between",
  },
  copyIcon:{
    marginLeft: "12px",
    cursor: "pointer",
    "&:hover": {
      color: "#3838cf",
    }
  }
  ,
  arrowDown: {
    transform: "rotateZ(90deg)"
  },
  arrowUp: {
    transform: "rotateZ(-90deg)"
  }
});

const CardAppList = ({
  app,
  showApp,
  setApplication,
  env,
  setApplicationEnv,
  auth,
  getAppPages,
  backAction,
  moreActions,
  showEnv,
  envTitle,
  envType,
  clickAction,
  isConfiguratorRoute = false,
  apps = [],
  envId,
  isGlobal,
  pages=[],
  pagesFilter=false,
  isConfiguration,
  fetchWidgetList,
  resetSearchForm,
  dispatchShowSnackBar
}) => {
  const { access_token, id_token } = auth;
  const [expand, setExpand] = useState();
  const classes = useStyles();
  const copyText = async (e,text)=>{
    e.stopPropagation();
    if ('clipboard' in navigator) {
       await navigator.clipboard.writeText(text);
       dispatchShowSnackBar({
        show: true,
        message: `Text Copied ..`,
        severity: 3,
      });
    } else {
       document.execCommand('copy', true, text);
       dispatchShowSnackBar({
        show: true,
        message: `Text Copied ..`,
        severity: 3,
      });
    }
    setTimeout(() => {
    dispatchShowSnackBar({ show: false });  
    }, 1000);
  }
  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-apps": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        onClick={() => setExpand(!expand)}
      >
        {backAction && (
          <Grid item xs="auto">
            <CardActions className="MuiCardActions-start">
              <IconButton edge="end" onClick={() => backAction()} data-testid="backFromApp_btn">
                <VfSvgIcon icon="arrowLeft" />
              </IconButton>
            </CardActions>
          </Grid>
        )}
        <Grid item xs>
          <CardActionArea>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <CardContent>
                  <Grid container alignItems="center" spacing={6}>
                    <Grid item xs="auto">
                      {app.iconBase64 && <CardMedia image={app.iconBase64} />}
                      {!app.iconBase64 && (
                        <VfSvgIcon icon="vodafone" viewBox={24} />
                      )}
                    </Grid>
                    <Divider
                      flexItem
                      orientation="vertical"
                      variant="fullWidth"
                    />
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1" component="h5">
                            {app.title}
                          </Typography>
                        </Grid>
                        {isConfiguration&&<PagesList fetchWidgetList={fetchWidgetList} envId={envId} app={app}
                        />}
                      </Grid>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs="auto">
                          <VfSvgIcon
                            icon={app.appType}
                            viewBox={24}
                            className="MuiSvgIcon-fontSizeXSmall"
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <VfSvgIcon
                            icon={app.platform}
                            viewBox={24}
                            className="MuiSvgIcon-fontSizeXSmall"
                          />
                        </Grid>
                        {app.hasAuth && (
                          <Grid item xs="auto">
                            <VfTooltip title={app.authProvider}>
                              <VfSvgIcon
                                icon="lock"
                                viewBox={24}
                                className="MuiSvgIcon-fontSizeXSmall"
                              />
                            </VfTooltip>
                          </Grid>
                        )}
                        <Grid item xs="auto">
                          <Typography variant="body2" color="textSecondary">
                            {app.theming === "MVA10" ? "WS10" : app.theming}
                          </Typography>
                        </Grid>
                        {!!(env && !env.isGlobal && !isConfiguratorRoute) && <Grid item xs="auto">
                          <Typography variant="body2" color="textSecondary">
                            <Avatar
                              alt={app.deploymentFlow === DEPLOYMENT_FLOWS.S3 ? "AWS S3 logo" : "Kubernetes logo"}
                              src={app.deploymentFlow === DEPLOYMENT_FLOWS.S3 ? AWS_S3 : Kubernetes}
                              style={{ width: 16, height: 16 }}
                            />
                          </Typography>
                        </Grid>}
                        {app.unmanaged && (
                          <Grid item xs="auto">
                            <Typography variant="body2" color="secondary">
                              Unmanaged
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {app.tags.length > 0 && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {app.tags ? app.tags.join(", ") : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs="auto" data-testid="arrowAndStausContainer" >
                      {!showEnv && <IconButton onClick={() => setExpand(!expand)} className="MuiIconButton-colorSecondary" data-testid="expand_collapse_btn">
                        <ArrowForwardIos className={
                          classNames({
                            [classes.arrowUp]: expand,
                            [classes.arrowDown]: !expand,
                          })
                        } />
                      </IconButton>}
                      {app.status && (
                        <Typography variant="body2" component="div" data-testid="app_status">
                          {app.status}
                        </Typography>
                      )}
                    </Grid>
                    {showEnv && (
                      <Grid item xs="auto">
                        <Chip
                          className={classNames({
                            [`MuiChip-${envTitle.toLowerCase()}`]: true,
                            "MuiChip-selected": true,
                          })}
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon={envType}
                              fontSize="small"
                            />
                          }
                          label={envTitle}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>

      </Grid>
      <Collapse in={expand}>
        {pagesFilter?pages.map(page => <Card className={classes.card}>
          <CardContent className={classes.CardContent} 
          onClick={() => {
            if (clickAction) {
              clickAction(app,page, envId, env, envType, isGlobal);
            } else {
              showApp(true);
              setApplication({
                ...app,
                isDeployed: !!(env && !env.isGlobal),
              });
              setApplicationEnv(env);
              getAppPages({
                access_token,
                id_token,
                application: app._id,
                envId: env.id,
              });
            }
          }}
          >
            <Typography gutterBottom variant="h5" component="div" className={classes.CardContentItem}>
            {page.title}
            </Typography>
            {page.updatedAt && <Typography gutterBottom variant="h5" component="div" className={classes.CardContentItem}>
              {`${i18n.t('lastUpdate')}: ${new Date(page.updatedAt).toLocaleDateString()}`}
            </Typography>}
          </CardContent>

        </Card>):apps.map(app => <Card className={classes.card}>
            <Typography className={classes.AppInfoBasic} >
         <CardContent className={classes.CardContent} onClick={() => {
            if (clickAction) {
              clickAction(app, envId, env, envType, isGlobal);
            } else {
              showApp(true);
              setApplication({
                ...app,
                isDeployed: !!(env && !env.isGlobal),
              });
              setApplicationEnv(env);
              getAppPages({
                access_token,
                id_token,
                application: app._id,
                envId: env.id,
              });
            }
          }}>
            <Typography component="div" className={classes.AppInfo} >
              <Typography component="div" className={classes.AppInfoBasic}>
                  <Typography gutterBottom variant="h5" component="div" className={classes.CardContentItem} dtat-testid="appVersionItem">
                    {`${i18n.t('version')}: ${app.version}`}
                  </Typography>
                {app.updatedAt && (
                  <>
                    <Typography 
                      gutterBottom 
                      variant="h5" 
                      component="div" 
                      className={classes.CardContentItem}
                    >
                      {`${i18n.t('lastUpdate')}: ${new Date(app.updatedAt).toLocaleDateString()}`}
                    </Typography>
                    {envType != "global" && app.status == 'Failed' && (
                      <Typography 
                       gutterBottom 
                       variant="h5" 
                       component="div" 
                       className={classes.CardContentItem}
                       color="secondary"
                     >
                       {app.status}
                     </Typography>
                    )}
                  </>

                )}

              </Typography>

              {envType != "global" && app.status == 'Deployed' && <Typography variant="h5" className={classes.buttonContainer} component="div">
               {app.dockerImageTag && <Typography onClick={(event)=>{event.stopPropagation()}} className={classes.infoButton} component="span" data-testid="imageTag_details">
                {`${i18n.t('imageTag')}: ${app.dockerImageTag}`}
                <Typography component="span" data-testid="CopyimageTag_btn"> <FileCopyIcon onClick={($event)=> copyText($event,app.dockerImageTag)}
                 className={classes.copyIcon} data-testid="CopyimageTag_icon"/></Typography>  
               </Typography >}

              {app.application && <Typography onClick={(event)=>{event.stopPropagation()}} className={classes.infoButton} component="span" data-testid="idVersion_details">
              {`ID:  ${app.application}`} 
              <Typography component="span" data-testid="idVersion_btn"> <FileCopyIcon onClick={($event)=> copyText($event,app.application)}  
                className={classes.copyIcon} data-testid="idVersion_icon"/></Typography>
              </Typography>}
              </Typography>}
            </Typography>
          
           
          </CardContent> 
          <>
            {moreActions && (
              <AppActionsMenu app={app} env={env} envType={envType} resetSearchForm={resetSearchForm} />
            )}
          
          </> 
            </Typography>
          
       

        </Card>)}
      </Collapse>
    </Card>
  );
};

export default CardAppList;
